import styled from 'styled-components'

export const AboutSectionContainer = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #101d30; 
    z-index: 998;

    @media screen and (max-width: 768px){
}
  
    @media screen and (max-width: 480px){
}
`
export const AboutBackgroundText = styled.div `
color: rgba(167, 167, 167, 0.2);
background: none;
background: rgba(0, 0, 0, 0);
font-weight: bold;
font-size: 30vw;
position: absolute;
vertical-align: auto;
z-index: 1;

@media screen and (max-width: 480px){
    color: rgba(167, 167, 167, 0.5);
}
`

export const AboutWrapper = styled.div `
    background-color: #6b0905;
    color: #aaaaaa;
    background: rgba(16, 29, 48, 0.089);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.25);
    border-left: 1px solid rgba(59, 82, 120, 0.452);
    border-bottom: 1px solid rgba(59, 82, 120, 0.452);
    border-right: 1px solid rgba(59, 82, 120, 0.25);
    border-top: 1px solid rgba(59, 82, 120, 0.25);
    backdrop-filter: blur(5px);

    z-index: 1;

    position: absolute;

    height: 80vh;
    width: 78vw;

    @media screen and (max-width: 768px){


}
  
    @media screen and (max-width: 480px){
    width: 80vw;
}
`

export const AboutTitle = styled.div `


@media screen and (max-width: 768px){

}
  
    @media screen and (max-width: 480px){

}
`

export const ProfileImg = styled.image `



z-index: 998;

@media screen and (max-width: 768px){
    
}
  
    @media screen and (max-width: 480px){

    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;

}

`

export const AboutRow1 = styled.div `
background-color: transparent;

margin: 5px 20px 0px 20px;

top: 0px;
right: 0px;

border-bottom: 2px dashed;
p {
    background-color: transparent;
}

font-size: 20px;
    @media screen and (max-width: 768px){
}
  
    @media screen and (max-width: 480px){
    
    
}
`

export const Rtro = styled.div `
`


export const Column1 = styled.div `
    background-color: transparent;
    backdrop-filter: blur(5px);

    font-weight: 600;
    margin: 10px;
    font-size: 18px;
    

    margin: 20px 20px 0px 20px;
    @media screen and (max-width: 768px){
}
  
    @media screen and (max-width: 480px){


}
`

export const TextWrapper1 = styled.div `

    @media screen and (max-width: 768px){
}
  
    @media screen and (max-width: 480px){

}
`
export const Technologies = styled.div `
`

export const Logo = styled.div `

`